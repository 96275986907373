/**
 * global var to reuse for export
 */

let map

/**
 * openlayers mapInit
 */

const mapInit = (fixed) => {
  /**
   * from the fixed get:
   *  - the center of the map
   *  - extent of the visible map
   */

  const setPos = fixed.defpos.split(',')
  const setExt = fixed.bounds.split(',')
  const ext = [parseInt(setExt[0]), parseInt(setExt[1]), parseInt(setExt[2]), parseInt(setExt[3])]

  /**
   * PROJECTION
   */

  const projection = new ol.proj.Projection({
    code: 'EPSG:27700',
    getPointResolution: (r) => {
      return r
    },
    units: 'm'
  })

  /**
   * mapcache layer
   */

  // const maplayer = new ol.layer.Tile({
  //   extent: ext,
  //   preload: Infinity,
  //   source: new ol.source.TileWMS({
  //     url: 'https://www.buchananmapping.co.uk/mapcache/?',
  //     params: {
  //       'LAYERS': fixed.cachelayer,
  //       'TILED': true
  //     },
  //     ratio: 1,
  //     projection: 'EPSG:27700',
  //   })
  // })

  // const maplayer = new ol.layer.Tile({
  //   extent: ext,
  //   preload: Infinity,
  //   source: new ol.source.OSM()
  // })

  /**
   * ireland layer
   */

  let irelandLayer = new ol.layer.Vector({
    source: new ol.source.Vector({
      url: '/maps/ireland.geojson',
      format: new ol.format.GeoJSON()
    }),
    style: new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(245, 245, 245, .9)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgb(127, 127, 127)',
        width: 1
      })
    })
  })

  /**
   * map view
   */

  const view = new ol.View({
    projection: projection,
    center: [parseInt(setPos[0]), parseInt(setPos[1])],
    extent: ext,
    maxZoom: 8,
    minZoom: 6,
    zoom: 7
  })

  map = new ol.Map({
    interactions: ol.interaction.defaults({
      altShiftDragRotate: false
    }),

    /**
     * add controls to the map view
     */

    controls: ol.control.defaults({
      attributionOptions: ({
        collapsible: false
      })
    }),

    /**
     * add layers to the map view
     */

    layers: [irelandLayer],
    target: 'landingmap',
    view: view
  })

  /**
   * update the size of the map as we resize the container on load and resize
   */

  map.updateSize()

  /**
   * end map init
   */
}

/**
 * export the map object
 */

const getMap = () => map

/**
 * EXPORT
 */

export default {
  mapInit,
  getMap
}
