/**
 * First we will load all of this project's JavaScript modules
 */

import appCookie from './components/appcookies'
import events from './components/events'
import map from './components/landingmap'
import layer from './components/landinglayer'
import popup from './components/landingpopup'
import regionsearch from './common/regionsearch'

let fixed = {
  'bounds': '0, 0, 700000, 1250000',
  'defpos': '365000, 470000',
  'cachelayer': 'bccache2',
  'url': 'https://www.collisionmap.uk',
  'wmsfeat': '/cgi-bin/areas?&amp;service=WMS',
}

if (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('traffwebdev') > -1 || window.location.href.indexOf('http://collisionmap.test/') > -1) {
  fixed = {
    'bounds': '0, 0, 700000, 1250000',
    'defpos': '365000, 470000',
    'cachelayer': 'bccache2',
    'url': 'https://www.traffwebdev.uk',
    'wmsfeat': '/cgi-bin/areas?&amp;service=WMS',
  }
}

appCookie.intializeCookie()
map.mapInit(fixed)
events.initEvents(map.getMap())
layer.initLayer(fixed)
layer.addlayers()
regionsearch.initRegionSearch()
popup.infoPopup(map.getMap(), layer.colorLayer())
// popup.popup(map.getMap(), layer.interactionLayer(), layer.colorLayer())
$('.landgear').trigger('click')
// use the previous functions on the load event
document.addEventListener('DOMContentLoaded', () => {
  events.setPosition(map.getMap(), 500)
})

// use the previous functions on the window resize event
window.addEventListener('resize', () => {
  events.setPosition(map.getMap(), 0)
  $('[data-toggle="tooltip"]').tooltip('update')
})
