/**
/**
 * we create a big function to initialize all the events inside
 * and we call this function in the main app
 */

// const initEvents = (mapView, overlay) => {
const initEvents = (map) => {
  /**
   * hide accessibility modal when the form is opening
   * we close the first modal because when you open the other modal
   * and you are again in the first one it doesn't work, you cannot click on anything
   */

  $('#accsAform').click(() => {
    $('#accessibility').modal('hide')
  })

  /**
   * hide privacy modal when the form is opening
   * we close the first modal because when you open the other modal
   * and you are again in the first one it doesn't work, you cannot click on anything
   */

  $('#privacylink').click(() => {
    $('#privacy').modal('hide')
  })

  /**
   * reset the form on close
   */

  $('#accsform').on('hidden.bs.modal', function () {
    $('.accsformdiv').css('display', 'block')
    $('.accsformsuccess').addClass('d-none')
    $('.accsformerror').addClass('d-none')
    $('.accsform').trigger('reset')
  })

  /**
   * gear button in the map
   */

  $('.landgear').on('click', function () {
    $('.mapOptions').fadeToggle()
  })

  /**
   * tooltip
   */
  $('[data-toggle="tooltip"]').tooltip()
  /**
   * end initEvent
   */

  $('#switchbox').on('click', function () {
    // true dashboard, false map
    let info = $('#info')
    info.tooltip('hide')
    if ($('#switchbox').prop('checked')) {
      $('#landingStats').removeClass('d-none')
      $('#mapStats').addClass('d-none')
    }
    else {
      $('#mapStats').removeClass('d-none')
      $('#landingStats').addClass('d-none')
      map.updateSize()
    }
  })
}

const setPosition = (map, time) => {
  /**
   * check if the navbar is open
   * close it if is open
   */

  let navIsOpen = $('.navbar-collapse.collapse.show').length

  if (navIsOpen) {
    $('#mainNav').removeClass('show')
  }
  // check if tooltips open
  $('[data-toggle="tooltip"]').tooltip('hide')

  if ($(window).width() < 768) {
    if ($('.mobcontrol').css('display') === 'block') {
    }
    else {
      $('.website').addClass('d-none').removeClass('d-inline-block')
      $('.mobcontrol').addClass('d-inline-block').removeClass('d-none')
      $('#switchbox').trigger('click') // turn on dashboard view
    }
  }
  else {
    $('.website').addClass('d-inline-block').removeClass('d-none')
    $('.mobcontrol').addClass('d-none').removeClass('d-inline-block')
    $('#mapStats').removeClass('d-none')
    $('#landingStats').removeClass('d-none')
  }

  // navbar height
  let headHeight = $('.navbar').outerHeight()
  $('#content').css('top', headHeight)
  let maxWindow = window.innerHeight - headHeight - $('footer').outerHeight()
  /**
   * set lists height
   */
  setTimeout(function (time) {
    let regionTitle = $('.region').outerHeight()
    let statsTitle = $('.totals').outerHeight() * 3
    let usableSpace = (maxWindow - regionTitle - statsTitle) / 4
    let marginTop = (usableSpace / 2) - ($('.svgContainer').outerHeight() / 2) + 10

    if (window.innerWidth > 1400 || window.innerWidth === 1400) {
      usableSpace = usableSpace + 10
      maxWindow = maxWindow - 10
      $('.lists').css('max-height', usableSpace)
      $('.svgContainer').css('margin-top', marginTop)
      $('#landingmap').css('height', maxWindow)
      map.updateSize()
    }
    if (window.innerWidth > 992 || window.innerWidth === 992) {
      maxWindow = maxWindow - 10
      $('.lists').css('max-height', usableSpace)
      $('.svgContainer').css('margin-top', marginTop)
      $('#landingmap').css('height', maxWindow)
      map.updateSize()
    }
    else if (window.innerWidth > 768 || window.innerWidth === 768) {
      $('.lists').css('max-height', '200px')
      $('.svgContainer').css('margin-top', marginTop)
      $('#landingmap').css('height', '70vh')
      map.updateSize()
    }
    else if (window.innerWidth > 319) {
      $('.lists').css('max-height', '200px')
      $('.svgContainer').css('margin-top', '0')
      $('#landingmap').css('height', maxWindow)
      map.updateSize()
    }
  }, time)

  /**
   * end setPosition
   */
}

/**
 * EXPORT
 */

export default {
  initEvents,
  setPosition
}
