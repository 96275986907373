/*
 *in this module there are all the functions to create
 *  - the popup for AM
 *  - hover popup
 *  - display the correct popup (AM, AM PLUS)
 */

/**
 * infoPopup function
 */

const infoPopup = (view, getColorLayer) => {
  /**
   * projection used in the change of cursor style
   */

  const projection = new ol.proj.Projection({
    code: 'EPSG:27700',
    getPointResolution: (r) => {
      return r
    },
    units: 'm'
  })

  let colorLayer = getColorLayer

  /**
   * pointermove event listener
   */

  view.on('pointermove', function (evt) {
    /**
     * if you are moving the map return
     */

    if (evt.dragging) {
      return
    }

    /**
     * change the style of the cursor
     */

    let pixel = view.getEventPixel(evt.originalEvent)
    let hit = view.forEachLayerAtPixel(pixel, () => {
      return true
    }, null, (layer) => {
      return layer === colorLayer
    })
    view.getTargetElement().style.cursor = hit ? 'pointer' : ''
  })

  /**
   * pointermove event listener
   */

  /**
   * pointermove event listener for the hover popup
   */

  let stillMoving = []
  let info = $('#info')
  info.tooltip({
    animation: true,
    trigger: 'manual'
  })

  /**
   * add the pointermove event to the map
   */

  view.on('pointermove', function (evt) {
    if (evt.dragging) {
      info.tooltip('hide')
      return
    }

    /**
     * create the hover popup with delay
     */

    $(function () {
      /**
       * add my call to array
       */

      stillMoving.push(true)

      setTimeout(() => {
        /**
         * remove my call to array
         */

        stillMoving.shift()

        /**
         * check if more calls are pending
         * if yes stop propagation
         */

        if (stillMoving[0]) {
          // return
          info.tooltip('hide')
        }
        else {
          /**
           * change the style of the cursor
           */

          let pixel = view.getEventPixel(evt.originalEvent)
          let feature = view.forEachLayerAtPixel(pixel, (feature) => {
            return true
          }, null, (layer) => {
            return layer === colorLayer
          })

          /**
           * get the resolution
           * get the coordinate
           * define the layer to query
           */

          let viewResolution = view.getView().getResolution()
          let coordinate = evt.coordinate
          let url

          url = colorLayer.getSource().getGetFeatureInfoUrl(coordinate, viewResolution, projection, {
            'INFO_FORMAT': 'text/html',
            'FEATURE_COUNT': '10'
          })

          /**
           * check if feature and url is not empty
           */

          if (feature && url) {
            /**
             * send a XMLHttpRequest to the url and get back the xml files compiled
             */

            let xhttp = new XMLHttpRequest()
            xhttp.open('GET', url, true)
            xhttp.send()
            xhttp.onreadystatechange = function (aEvt) {
              /**
               * check the readyState and status
               */

              if (xhttp.readyState === 4 && xhttp.status === 200) {
                /**
                 * get the result from the XML
                 * - create a parser
                 * - parse the result
                 * - get the item element
                 */

                let parser = new window.DOMParser()
                let res = parser.parseFromString(xhttp.responseText, 'text/xml')
                let myitems = res.getElementsByTagName('item')

                /**
                 * check the length of the result
                 */

                if (myitems.length !== 0) {
                  /**
                   * get the coordinate to positioning the popup
                   */
                  let x = pixel[0]
                  let y = pixel[1] - 3

                  info.css({
                    left: x + 'px',
                    top: y + 'px'
                  })

                  info.tooltip('hide')
                    .attr('data-original-title', accshover(xhttp.responseText))
                    .tooltip('show')
                }
                /**
                 * if myitems.length is 0
                 */

                else {
                  /**
                   * reset the content of the popup
                   */

                  info.tooltip('hide')
                }

                /**
                 *  end if 4 && 200
                 * end onreadystatechange
                 * end if(feature && url)
                 * end else if (stillMoving[0])
                 */
              }
            }
          }
        }

        /**
         *  end setTimeout(() =>
         * end $(function ()
         * end pointermove event listener
         */
      }, 500)
    })
  })

  /**
   * end const infoPopup = (fixed, getSettings, view, getLayer, getOverlay) =>
   */
}

/**
 * get fields from the xml template
 */

const getFieldValue = (myitems, i, sfield) => {
  let sval = ''
  if (myitems[i].getElementsByTagName(sfield)[0].childNodes.length !== 0) {
    sval = myitems[i].getElementsByTagName(sfield)[0].firstChild.nodeValue

    /**
     * check if sval is === to -1
     * special case for tvp accsmap website
     */

    if (sval === '-1') {
      sval = '-'
    }
  }
  else {
    sval = ''
  }
  return sval
}

/**
 * hover popup creation
 */

const accshover = (xml) => {
  /**
   * get the result from the XML
   * - create a parser
   * - parse the result
   * - get the item element
   * - get the length of the item element
   * initialize the content of the popup to empty
   */

  let parser = new window.DOMParser()
  let url = parser.parseFromString(xml, 'text/xml')
  let featObj = url
  let myitems = featObj.getElementsByTagName('item')
  let myitemsLength = myitems.length
  let strText = ''

  /**
   * iterate through the result
   */

  for (let i = 0; i <= (myitemsLength - 1); i++) {
    /**
     * add a new line after the first iteration
     * in this way each result will be on a new line
     */

    if (i !== 0) {
      strText += '<br />'
    }

    /**
     * check if the node is accident
     */

    strText = strText + `${getFieldValue(myitems, i, 'name')}: ${getFieldValue(myitems, i, 'info')}${getFieldValue(myitems, i, 'label')}`
  }
  /**
   * return the hover popup
   */

  return strText
}

/**
 * EXPORT
 */

export default {
  infoPopup
}
