/**
 * import modules
 */

import map from './landingmap'

function updateList (region) {
  $.ajax({
    type: 'POST',
    url: '/landinglist',
    data: {
      region: region
    },
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'))
    },
    success: function (data) {
      /**
       * create the collision list
       */
      document.getElementById('collisionsList').innerHTML = ''
      let collList = []
      $.each(data[0], function (key, value) {
        collList.push(`<li>${value.name} <span class="1">${value.accs_actual}</span></li>`)
      })
      document.getElementById('collisionsList').innerHTML = collList.join(' ')
      $('#collisionsList').parent().scrollTop(0)

      /**
       * create the casualties list
       */
      document.getElementById('casualtiesList').innerHTML = ''
      let casList = []
      $.each(data[1], function (key, value) {
        casList.push(`<li>${value.name} <span class="2">${value.cas_actual}</span></li>`)
      })
      document.getElementById('casualtiesList').innerHTML = casList.join(' ')
      $('#casualtiesList').parent().scrollTop(0)

      /**
       * create the casualties list
       */
      document.getElementById('ksiList').innerHTML = ''
      let ksiList = []
      $.each(data[2], function (key, value) {
        ksiList.push(`<li>${value.name} <span class="3">${value.ksi_actual}</span></li>`)
      })
      document.getElementById('ksiList').innerHTML = ksiList.join(' ')
      $('#ksiList').parent().scrollTop(0)
    }
  })
}

/**
 * updateData function to run when a region is clicked and update the basic info
 */
function updateData (region) {
  $.ajax({
    type: 'POST',
    url: '/landing',
    data: {
      region: region
    },
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'))
    },
    success: function (data) {
      let rawdata = data[0]
      $('.region').text(rawdata.name)
      $('.totalCollisions').text(rawdata.accs_actual)
      $('.totalCasualties').text(rawdata.cas_actual)
      $('.totalKsi').text(rawdata.ksi_actual)
      $('.svgContainer').removeClass('green').removeClass('red')
      $('.collPercent').text(rawdata.accs_percent)
      $('.casPercent').text(rawdata.cas_percent)
      $('.ksiPercent').text(rawdata.ksi_percent)

      if (rawdata.accs_percent > 0) {
        $('.svgCollisions').addClass('red')
      }
      else if (rawdata.accs_percent < 0) {
        $('.svgCollisions').addClass('green')
      }

      if (rawdata.cas_percent > 0) {
        $('.svgCasualties').addClass('red')
      }
      else if (rawdata.cas_percent < 0) {
        $('.svgCasualties').addClass('green')
      }

      if (rawdata.ksi_percent > 0) {
        $('.svgKsi').addClass('red')
      }
      else if (rawdata.ksi_percent < 0) {
        $('.svgKsi').addClass('green')
      }
    }
  })
}

/**
 * global var to reuse for export
 *  - thematic map for colours
 *  - geojson/vector layer for interaction
 *  - select interaction
 */

let colorMap
let vectorLayer
let select

/**
 * initialise the layers
 */

let initLayer = (fixed) => {
  /**
   * get the extent of the visible map
   */

  const setExt = fixed.bounds.split(',')
  const ext = [parseInt(setExt[0]), parseInt(setExt[1]), parseInt(setExt[2]), parseInt(setExt[3])]

  /**
   * thematic layer
   */

  colorMap = new ol.layer.Tile({
    extent: ext,
    source: new ol.source.TileWMS({
      url: `${fixed.url}${fixed.wmsfeat}`,
      params: {
        'LAYERS': ['ukregions_accspercent'],
        format: 'image/png'
      },
      ratio: 1,
      crossOrigin: 'anonymous',
      projection: 'EPSG:27700'
    })
  })

  /**
   * interaction layer
   */

  vectorLayer = new ol.layer.Vector({
    source: new ol.source.Vector({
      url: '/maps/vectormap.geojson',
      format: new ol.format.GeoJSON()
    }),
    style: new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(255, 255, 255, 0.1)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgb(127, 127, 127)',
        width: 1
      })
    })
  })

  /**
   * interaction for the interaction layer
   */

  select = new ol.interaction.Select({
    style: new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(255, 255, 255, 0.1)'
      }),
      stroke: new ol.style.Stroke({
        color: 'rgb(255,255,255)',
        width: 2
      })
    })
  })

  let selectedFeatures = select.getFeatures()
  let int = ''

  selectedFeatures.on(['add', 'remove'], function (e) {
    /**
     * check if the event type is remove
     * wait 200ms
     * check that the event is still remove
     * run the updateData with Great Britain as a value
     * this prevent the updateData to run twice (remove and add) on change
     */

    int = e.type
    if (int === 'remove') {
      setTimeout(function () {
        if (int === 'remove') {
          updateData('Great Britain')
          updateList('Great Britain')
        }
      }, 200)
    }

    /**
     * else get the name of the region
     * check if the selection is more than one and remove the old one
     * run the updateData with the region's name
     */
    else {
      let names = selectedFeatures.getArray()
      let areasel = ''
      if (names.length > 1) {
        selectedFeatures.remove(names[0])
        int = 'add'
      }
      names.forEach(function (feature) {
        areasel = feature.getProperties().nuts118nm
      })
      if (areasel === 'Ireland' || areasel === 'Northern Ireland') {
        selectedFeatures.remove(names[0])
        return
      }
      setTimeout(function () {
        updateData(areasel)
        updateList(areasel)
      }, 100)
    }
  })

  /**
   * end let initLayer = (fixed) => {
   */
}

/**
 * update the layers
 */

const updateLayer = (value) => {
  colorMap.setVisible(false)
  colorMap.getSource().getParams().LAYERS = 'ukregions_' + value
  colorMap.getSource().refresh()
  colorMap.setVisible(true)
}

/**
 * update the legend
 */

const updateLegend = (value) => {
  $('.legend_show').addClass('d-none').removeClass('legend_show')
  switch (value) {
    case 'casroad':
      $('.cas_road_legend').removeClass('d-none').addClass('legend_show')
      break
    case 'fatmil':
      $('.fat_mil_legend').removeClass('d-none').addClass('legend_show')
      break
    case 'ksimil':
      $('.ksi_mil_legend').removeClass('d-none').addClass('legend_show')
      break
    case 'casmil':
      $('.cas_mil_legend').removeClass('d-none').addClass('legend_show')
      break
    case 'accspercent':
    case 'caspercent':
      $('.percent_legend').removeClass('d-none').addClass('legend_show')
      break
  }
}

/**
 * mapOtions radio button event listener
 */

$('.custom-control-label').on('click', function () {
  setTimeout(function () {
    $('input:radio').each(function () {
      let $this = $(this)
      let id = $this.attr('id')

      if ($(this).prop('checked')) {
        updateLayer(id)
        updateLegend(id)
      }
    })
  }, 200)
})

/**
 * export the layers
 */

const colorLayer = () => {
  return colorMap
}

const interactionLayer = () => {
  return vectorLayer
}

/**
 * export the addlayer
 */

const addlayers = () => {
  map.getMap().addLayer(colorMap)
  map.getMap().addLayer(vectorLayer)
  map.getMap().addInteraction(select)
  updateData('Great Britain')
  updateList('Great Britain')
}

/**
 * EXPORT
 */

export default {
  colorLayer,
  interactionLayer,
  addlayers,
  initLayer
}
