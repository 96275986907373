/**
 * in this module there are:
 *   - search for postcodes, countries, regions or local authorities
 */

const initRegionSearch = () => {
  var path = '/autocomplete'
  $('input.typeahead').typeahead({
    minLength: 2,
    highlight: true,
    source: function (query, process) {
      return $.get(path, {
        query: query
      }, function (data) {
        return process(data)
      })
    }
  })

  $('.search').keydown(function () {
    if ($('.RegionError').css('display') === 'block') {
      $('.RegionError').hide()
    }
  })

  $('.search').on('keypress', function (e) {
    if (e.which === 13) {
      $('#button-search').trigger('click')
    }
  })

  $('#button-search').on('click', function () {
    var current = $('input.typeahead').typeahead('getActive')
    if ($('#AIOSearch').val() === '') {
      return
    }
    if (current === undefined) {
      $('.RegionError').css('display', 'block')
    }
    if (current.name.toLowerCase() === $('#AIOSearch').val().toLowerCase()) {
      document.location.href = window.location.origin + '/' + current.type + '/' + current.code + '/' + current.name
    }
    else {
      $('.RegionError').css('display', 'block')
    }
  })
} // end initRegionSearch

/**
 * EXPORT
 */

export default {
  initRegionSearch
}
